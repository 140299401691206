import {
  ICamera,
  ICameraChangeSortIndexFunction,
  IDeleteCameraFunction,
  ISubmitCameraForm,
} from '@Models/';

export interface ICameraItem {
  camera: ICamera;
  previousCameraId?: number;
  nextCameraId?: number;
  handleSubmitForm: ISubmitCameraForm;
  handleDeleteCamera: IDeleteCameraFunction;
  handleChangeCameraSortIndex: ICameraChangeSortIndexFunction;
}

export enum CameraItemMovingEnum {
  MOVE_UP = 'Переместить вверх',
  MOVE_DOWN = 'Переместить вниз',
}
