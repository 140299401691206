import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowSVG = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.70711 4.29289C2.31658 3.90237 1.68342 3.90237 1.29289 4.29289C0.902369 4.68342 0.902369 5.31658 1.29289 5.70711L2.70711 4.29289ZM8 11L7.29289 11.7071C7.68342 12.0976 8.31658 12.0976 8.70711 11.7071L8 11ZM14.7071 5.70711C15.0976 5.31658 15.0976 4.68342 14.7071 4.29289C14.3166 3.90237 13.6834 3.90237 13.2929 4.29289L14.7071 5.70711ZM1.29289 5.70711L7.29289 11.7071L8.70711 10.2929L2.70711 4.29289L1.29289 5.70711ZM8.70711 11.7071L14.7071 5.70711L13.2929 4.29289L7.29289 10.2929L8.70711 11.7071Z'
      fill='#2D2D2D'
    />
  </svg>
);

export const ArrowSelect: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={ArrowSVG} {...props} />
);
