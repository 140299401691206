import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from '@Components/ui/Container';
import { useActions, useAppSelector } from '@Hooks/';
import { CustomSelect } from '@Components/ui/Select';
import { DefaultOptionType } from 'rc-select/lib/Select';
import styles from './SettingPage.module.less';
import { SettingParametersEnum, StoresTypeEnum } from '@Models/';
import { SettingCameras } from '@Pages/SettingsPage/SettingCameras';

export const SettingsPage = () => {
  const { store, storeType } = useAppSelector(state => state.stores);
  const { parameters, activeParameter } = useAppSelector(state => state.setting);
  const { setActiveSettingParameter } = useActions();
  const navigate = useNavigate();

  useEffect(() => {
    if (storeType === StoresTypeEnum.petbar) return;
    navigate('/records/');
  }, [store]);

  const handleChangeSettingParameter = (item: DefaultOptionType) => {
    setActiveSettingParameter(parameters.find(({ value }) => value === item.value) || null);
  };

  return (
    <Container title={`${store?.title.toUpperCase()} - Настройки`} isPageContainer>
      <CustomSelect
        value={activeParameter}
        options={parameters}
        onChange={handleChangeSettingParameter}
        className={styles.select}
      />
      {activeParameter?.value === SettingParametersEnum.cameraSetting && <SettingCameras />}
    </Container>
  );
};
