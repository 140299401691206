import React, { FC, PropsWithChildren } from 'react';

import classes from './MainLayout.module.less';
import { Layout } from 'antd';
import { Header } from '@Components/Header';

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <Layout.Content className={classes.content}>{children}</Layout.Content>
    </>
  );
};
