import React, { FC } from 'react';
import { Pagination, PaginationProps } from 'antd';
import clsx from 'clsx';

import { StrokeIcon } from '@Components/ui/icons';
import styles from './Pagination.module.less';
import { IPagination } from './Pagination.types';
import { pastRecordsPageCount } from '@Constants/';

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <a>
        <StrokeIcon />
      </a>
    );
  }
  if (type === 'next') {
    return (
      <a>
        <StrokeIcon style={{ transform: 'rotate(180deg)' }} />
      </a>
    );
  }
  return originalElement;
};

export const CustomPagination: FC<IPagination> = ({
  className,
  classNameWrap,
  current,
  total,
  onChange,
}) => {
  return (
    <div className={clsx(styles.paginationWrap, classNameWrap)}>
      <Pagination
        className={clsx(styles.pagination, className)}
        current={current}
        pageSize={pastRecordsPageCount}
        total={total}
        onChange={onChange}
        itemRender={itemRender}
      />
    </div>
  );
};
