import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import { useAuth } from '@Hooks/';
import { redirectPage } from '@Constants/';
import { BaseLayout } from '@Components/layouts';

export const NoAuthorizedWrapper = () => {
  const { isLogged } = useAuth();
  const outlet = useOutlet();

  if (isLogged) {
    return <Navigate to={redirectPage} />;
  }

  return <BaseLayout>{outlet}</BaseLayout>;
};
