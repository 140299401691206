import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Navigation.module.less';
import { CustomSelect } from '@Components/ui/Select';
import { INavigation } from './Navigations.types';
import { StoresTypeEnum } from '@Models/';

import settingIcon from '@Assets/images/icons/setting.svg';
import settingDisableIcon from '@Assets/images/icons/setting-disable.svg';
import logoutIcon from '@Assets/images/icons/logout.svg';

export const Navigation: FC<INavigation> = ({
  activeStoreId,
  storeType,
  stores,
  onChangeStore,
  onCloseDrawer,
  onLogout,
}) => {
  return (
    <nav className={styles.wrap}>
      <ul className={styles.list}>
        <li>
          <NavLink
            to={'/records'}
            onClick={onCloseDrawer}
            className={({ isActive }) => clsx(styles.link, isActive && styles.linkActive)}>
            Актуальные записи
          </NavLink>
        </li>
        <li>
          <NavLink
            to={'/past-records'}
            onClick={onCloseDrawer}
            className={({ isActive }) => clsx(styles.link, isActive && styles.linkActive)}>
            Прошедшие
          </NavLink>
        </li>
      </ul>
      <div>
        <CustomSelect value={activeStoreId} options={stores} onChange={onChangeStore} />
        <span className={styles.settingBtnWrap}>
          <NavLink
            to={'/setting'}
            onClick={onCloseDrawer}
            className={({ isActive }) =>
              clsx(
                styles.link,
                styles.linkWithImg,
                storeType !== StoresTypeEnum.petbar && styles.linkDisable,
                isActive && styles.linkActive,
                'setting-link',
              )
            }>
            <img
              src={storeType === StoresTypeEnum.petbar ? settingIcon : settingDisableIcon}
              alt='Настройки'
            />
          </NavLink>
          <NavLink
            to={'/logout'}
            onClick={onLogout}
            className={clsx(styles.link, styles.linkWithImg)}>
            <img src={logoutIcon} alt='Выйти из аккаунта' />
          </NavLink>
        </span>
      </div>
    </nav>
  );
};
