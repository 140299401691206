import React, { useEffect, useMemo, useState } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Button, Drawer, Modal } from 'antd';

import styles from './Header.module.less';
import { Container } from '@Components/ui/Container';
import { Logo } from '@Components/ui/Logo';
import { Navigation } from '@Components/Navigation';
import { useActions, useAppSelector, useAuth, useWindowSize } from '@Hooks/';
import { MenuOutlined } from '@ant-design/icons';
import { useGetStoresQuery } from '@Store/stores/stores.api';
import { StoresTypeEnum } from '@Models/';

export const Header = () => {
  const windowSize = useWindowSize();
  const { handleLogout } = useAuth();
  const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);
  const [isOpenLogoutModal, setOpenLogoutModal] = useState(false);
  const [isLoadingLogout, setLoadingLogout] = useState(false);
  const { stores, store, storeType } = useAppSelector(state => state.stores);
  const { setStore, setStores } = useActions();
  const { data } = useGetStoresQuery(undefined);

  useEffect(() => {
    if (data?.length) {
      setStores(data);
    }
  }, [data]);

  const handleChangeStore = (item: DefaultOptionType) => {
    setStore(stores.find(({ id }) => id === item.value) || null);
  };

  const openMobileMenu = () => {
    setVisibleMobileMenu(true);
  };

  const closeMobileMenu = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (storeType !== StoresTypeEnum.petbar && e.currentTarget.classList.contains('setting-link')) {
      e.preventDefault();
    } else {
      setVisibleMobileMenu(false);
    }
  };

  const openLogoutModal = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    setOpenLogoutModal(true);
  };

  const handleLogoutModalConfirmed = async () => {
    setLoadingLogout(true);
    await handleLogout();
    setOpenLogoutModal(false);
    setLoadingLogout(false);
  };

  const closeLogoutModal = () => {
    setOpenLogoutModal(false);
  };

  const NavigationBar = useMemo(
    () => (
      <Navigation
        activeStoreId={store ? { label: store.title.toUpperCase(), value: store.id } : null}
        storeType={storeType}
        stores={stores.map(({ title, id }) => ({ label: title.toUpperCase(), value: id }))}
        onChangeStore={handleChangeStore}
        onCloseDrawer={closeMobileMenu}
        onLogout={openLogoutModal}
      />
    ),
    [store, stores],
  );

  return (
    <header className={styles.wrap}>
      <Container className={styles.container}>
        <Logo type={storeType} className={styles.logo} />
        {windowSize.width !== undefined && windowSize.width > 1000 ? (
          <div className={styles.nav}>{NavigationBar}</div>
        ) : (
          <Drawer
            title={store?.title}
            width={320}
            placement='right'
            onClose={closeMobileMenu}
            visible={visibleMobileMenu}>
            {NavigationBar}
          </Drawer>
        )}
        <Button className={styles.menuBtn} onClick={openMobileMenu} icon={<MenuOutlined />} ghost />
      </Container>
      <Modal
        title='Вы уверены, что хотите выйти?'
        centered={true}
        closable={false}
        confirmLoading={isLoadingLogout}
        visible={isOpenLogoutModal}
        onOk={handleLogoutModalConfirmed}
        onCancel={closeLogoutModal}
        okText={'Выйти'}
        bodyStyle={{ display: 'none' }}
        width={'375px'}
      />
    </header>
  );
};
