import React, { useEffect, useState } from 'react';
import { message, PaginationProps } from 'antd';
import moment from 'moment';

import styles from './RecordsPage.module.less';
import { useAppSelector } from '@Hooks/';
import { Container } from '@Components/ui/Container';
import { List } from '@Components/ui/List';
import { useLazyGetPastRecordsQuery } from '@Store/records';
import { Record } from '@Components/Record';
import { ListNoResult } from '@Components/ui/ListNoResult';
import { CustomDatePicker } from '@Components/ui/DatePicker';
import { ImageTypesEnum, IOnChangeImage, IOnDeleteImage } from '@Models/';
import {
  useChangeAfterImageMutation,
  useChangeBeforeImageMutation,
  useDeleteAfterImageMutation,
  useDeleteBeforeImageMutation,
  useLazyGetRecordsLiqQuery,
} from '@Store/records-liq';

export const PastRecordsPage = () => {
  const [dateValue, setDateValue] = useState<moment.Moment>(moment());
  const { store } = useAppSelector(state => state.stores);
  const [getPastRecords, { data: response, isFetching, isError, isLoading }] =
    useLazyGetPastRecordsQuery();
  const [getRecordsLiq, { data: recordsLiq }] = useLazyGetRecordsLiqQuery();
  const [changeBeforeImage] = useChangeBeforeImageMutation();
  const [changeAfterImage] = useChangeAfterImageMutation();
  const [deleteBeforeImage] = useDeleteBeforeImageMutation();
  const [deleteAfterImage] = useDeleteAfterImageMutation();

  useEffect(() => {
    if (store?.id) {
      getPastRecords({
        company_id: store.id,
        start_date: dateValue.format('YYYY-MM-DD'),
        end_date: dateValue.format('YYYY-MM-DD'),
      });
    }
  }, [dateValue, store]);

  const handleChangeDateValue = (value: moment.Moment | null) => {
    if (value) setDateValue(value);
  };

  const handleChangePage: PaginationProps['onChange'] = page => {
    if (!store) return;
    getPastRecords({
      company_id: store.id,
      start_date: dateValue.format('YYYY-MM-DD'),
      end_date: dateValue.format('YYYY-MM-DD'),
      page: page,
    });
  };

  useEffect(() => {
    if (!response?.data.length) return;
    getRecordsLiq({ yclientsIds: response.data.map(({ id }) => id) });
  }, [response]);

  const handleChangeImage: IOnChangeImage = async (
    yclientsId,
    file,
    imageType,
    onDisableLoading,
  ) => {
    try {
      if (typeof file === 'string') return;
      if (imageType === ImageTypesEnum.BEFORE) {
        await changeBeforeImage({ yclientsId, beforeImageFile: file }).unwrap();
      } else {
        await changeAfterImage({ yclientsId, afterImageFile: file }).unwrap();
      }
      // eslint-disable-next-line
    } catch (e: any) {
      if (e.data.message) message.error(e.data.message);
      onDisableLoading();
    }
  };

  const handleDeleteImage: IOnDeleteImage = async (yclientsId, imageType, onDisableLoading) => {
    try {
      if (imageType === ImageTypesEnum.BEFORE) {
        await deleteBeforeImage(yclientsId).unwrap();
      } else {
        await deleteAfterImage(yclientsId).unwrap();
      }
      // eslint-disable-next-line
    } catch (e: any) {
      if (e.data.message) message.error(e.data.message);
      onDisableLoading();
    }
  };

  return (
    <Container title={`${store?.title.toUpperCase()} – прошедшие записи`} isPageContainer>
      <CustomDatePicker
        value={dateValue}
        onChange={handleChangeDateValue}
        className={styles.datePicker}
      />
      <List
        isLoading={isFetching || isLoading}
        onChangePage={handleChangePage}
        currentPage={response?.meta?.page}
        totalRecords={response?.meta?.total_count}>
        {!isError && response?.data?.length ? (
          response?.data?.map(record => (
            <li key={record.id + 'past'}>
              <Record
                record={record}
                liqData={recordsLiq ? recordsLiq[record.id] : undefined}
                onChangeImage={handleChangeImage}
                onDeleteImage={handleDeleteImage}
                isEditCameras={false}
              />
            </li>
          ))
        ) : (
          <ListNoResult
            isLoading={isFetching || isLoading}
            isError={isError}
            errorText={'При загрузке записей произошла ошибка'}
            emptyText={'Нет записей на этот день'}
          />
        )}
      </List>
    </Container>
  );
};
