import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowSVG = () => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 8C0.723858 8 0.5 8.22386 0.5 8.5C0.5 8.77614 0.723858 9 1 9L1 8ZM15.3536 8.85355C15.5488 8.65829 15.5488 8.34171 15.3536 8.14645L12.1716 4.96447C11.9763 4.7692 11.6597 4.7692 11.4645 4.96447C11.2692 5.15973 11.2692 5.47631 11.4645 5.67157L14.2929 8.5L11.4645 11.3284C11.2692 11.5237 11.2692 11.8403 11.4645 12.0355C11.6597 12.2308 11.9763 12.2308 12.1716 12.0355L15.3536 8.85355ZM10.8191 8.5V8V8.5ZM1 9H10.8191V8H1L1 9ZM10.8191 9H15V8H10.8191V9Z'
      fill='currentColor'
    />
  </svg>
);

export const ArrowIcon: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={ArrowSVG} {...props} />
);
