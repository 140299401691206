import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const PhotoSVG = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.1942 20.6651H2.80582C2.32772 20.6624 1.86998 20.4713 1.5319 20.1332C1.19383 19.7951 1.00271 19.3374 1 18.8593V9.36585C1 8.88692 1.19026 8.42761 1.52891 8.08895C1.86757 7.75029 2.32688 7.56004 2.80582 7.56004H5.28236C5.33908 7.55776 5.3936 7.53755 5.4381 7.50232C5.4826 7.46709 5.51478 7.41865 5.53002 7.36398L6.01501 5.37242C6.11191 4.98049 6.33735 4.63233 6.65533 4.38355C6.97332 4.13478 7.3655 3.99974 7.76923 4H16.1173C16.521 3.99974 16.9132 4.13478 17.2312 4.38355C17.5491 4.63233 17.7746 4.98049 17.8715 5.37242L18.3565 7.36398C18.3717 7.41865 18.4039 7.46709 18.4484 7.50232C18.4929 7.53755 18.5474 7.55776 18.6041 7.56004H21.1942C21.4313 7.56004 21.6661 7.60675 21.8852 7.6975C22.1043 7.78825 22.3034 7.92126 22.4711 8.08895C22.6388 8.25664 22.7718 8.45571 22.8625 8.6748C22.9533 8.89389 23 9.12871 23 9.36585V18.8593C22.9973 19.3374 22.8062 19.7951 22.4681 20.1332C22.13 20.4713 21.6723 20.6624 21.1942 20.6651V20.6651ZM2.80582 9.15948C2.7374 9.15948 2.67178 9.18665 2.6234 9.23503C2.57502 9.28341 2.54784 9.34903 2.54784 9.41745V18.9109C2.54784 18.9793 2.57502 19.0449 2.6234 19.0933C2.67178 19.1417 2.7374 19.1689 2.80582 19.1689H21.1942C21.2626 19.1689 21.3282 19.1417 21.3766 19.0933C21.425 19.0449 21.4522 18.9793 21.4522 18.9109V9.36585C21.4401 9.30671 21.4077 9.25366 21.3605 9.21595C21.3134 9.17824 21.2545 9.15826 21.1942 9.15948H18.6041C18.1985 9.16101 17.8044 9.02518 17.4858 8.7741C17.1673 8.52301 16.9431 8.17147 16.8499 7.77674V7.77674L16.3649 5.7955C16.3512 5.73999 16.3194 5.69062 16.2746 5.65513C16.2298 5.61965 16.1744 5.60005 16.1173 5.59944H7.76923C7.71158 5.59818 7.6553 5.61707 7.61009 5.65286C7.56488 5.68865 7.53358 5.7391 7.52158 5.7955L7.03659 7.72514C6.95357 8.12941 6.73382 8.49275 6.41432 8.75399C6.09482 9.01523 5.69507 9.15842 5.28236 9.15948H2.80582Z'
      fill='currentColor'
    />
    <path
      d='M12.0002 17.1047C11.2299 17.1067 10.4763 16.8801 9.83498 16.4534C9.19366 16.0267 8.69344 15.4192 8.39771 14.708C8.10199 13.9967 8.02408 13.2136 8.17386 12.458C8.32364 11.7024 8.69436 11.0083 9.23904 10.4636C9.78372 9.91897 10.4778 9.54825 11.2334 9.39847C11.989 9.24869 12.7721 9.3266 13.4833 9.62232C14.1946 9.91804 14.8021 10.4183 15.2288 11.0596C15.6554 11.7009 15.8821 12.4545 15.8801 13.2248C15.8774 14.253 15.4677 15.2382 14.7407 15.9653C14.0136 16.6923 13.0283 17.102 12.0002 17.1047ZM10.696 11.3031C10.3094 11.5592 10.0076 11.9243 9.82872 12.3522C9.64985 12.78 9.60198 13.2513 9.6912 13.7063C9.78041 14.1614 10.0027 14.5797 10.3299 14.9084C10.657 15.237 11.0744 15.4611 11.5291 15.5523C11.9837 15.6436 12.4552 15.5978 12.8838 15.4208C13.3125 15.2438 13.6789 14.9436 13.9367 14.5582C14.1946 14.1727 14.3322 13.7194 14.3322 13.2557C14.3363 12.9464 14.2791 12.6393 14.1639 12.3522C14.0486 12.0651 13.8777 11.8037 13.6608 11.583C13.444 11.3623 13.1856 11.1868 12.9006 11.0666C12.6156 10.9463 12.3591 10.8812 12.0002 10.8824C11.465 10.8842 10.8726 11.1861 10.696 11.3031Z'
      fill='currentColor'
    />
  </svg>
);

export const Photo: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={PhotoSVG} {...props} />
);
