import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISettingParameter, SettingParametersEnum } from '@Models/';
import { LS_SETTING_KEY } from '@Constants/';
import { UseLocalStorage } from '@Helpers/';
interface StoresState {
  parameters: ISettingParameter[];
  activeParameter: ISettingParameter | null;
}

const storesLC = new UseLocalStorage<StoresState>(LS_SETTING_KEY, {
  parameters: [
    {
      label: SettingParametersEnum.cameraSetting,
      value: SettingParametersEnum.cameraSetting,
    },
  ],
  activeParameter: {
    label: SettingParametersEnum.cameraSetting,
    value: SettingParametersEnum.cameraSetting,
  },
});

const initialState: StoresState = storesLC.get();

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsParameters(state, action: PayloadAction<ISettingParameter[]>) {
      state.parameters = action.payload;
      storesLC.set(state);
    },
    setActiveSettingParameter(state, action: PayloadAction<ISettingParameter | null>) {
      state.activeParameter = action.payload;
      storesLC.set(state);
    },
  },
});

export const settingActions = settingsSlice.actions;
export const settingReducer = settingsSlice.reducer;
