import React, { FC, useCallback, useState } from 'react';
import { Button, Dropdown, Form, Input, Popconfirm, Menu, MenuProps } from 'antd';
import { CameraItemMovingEnum, ICameraItem } from './CameraItem.types';
import styles from './CameraItem.module.less';
import { SettingPencil, SettingMenu, SettingDelete } from '@Components/ui/icons';
import { ICreateCamera, ISubmitCameraForm } from '@Models/';
import { CameraFormEnum } from '@Pages/SettingsPage/SettingCameras/CameraForm/CameraForm.types';
import { FieldData } from 'rc-field-form/lib/interface';
import { SettingSave } from '@Components/ui/icons/SettingSave';

export const CameraItem: FC<ICameraItem> = ({
  previousCameraId,
  nextCameraId,
  camera,
  handleSubmitForm,
  handleDeleteCamera,
  handleChangeCameraSortIndex,
}) => {
  const [form] = Form.useForm<ICreateCamera>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    camera ? Object.values(camera).some(({ value }) => !!value) : true,
  );

  const menu = useCallback(() => {
    const upCls = previousCameraId ? styles.menu_label : styles.menu_label_dis;
    const downCls = nextCameraId ? styles.menu_label : styles.menu_label_dis;

    return (
      <Menu
        onClick={onMenuClick}
        items={[
          {
            key: CameraItemMovingEnum.MOVE_UP,
            label: <div className={upCls}>{CameraItemMovingEnum.MOVE_UP}</div>,
          },
          {
            key: CameraItemMovingEnum.MOVE_DOWN,
            label: <div className={downCls}>{CameraItemMovingEnum.MOVE_DOWN}</div>,
          },
        ]}
      />
    );
  }, [previousCameraId, nextCameraId]);

  const handleChangeEditMode = () => {
    setIsEditMode(true);
  };

  const onSubmitForm: ISubmitCameraForm = async values => {
    await handleSubmitForm(values, camera.id);
    setIsEditMode(false);
  };

  const onDeleteCamera = async () => {
    await handleDeleteCamera({ id: camera.id });
    setIsEditMode(false);
  };
  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const hasEmptyValues = allFields.some(({ value }) => !value);
    setIsDisabled(hasErrors || hasEmptyValues);
  };

  const onMenuClick: MenuProps['onClick'] = e => {
    const isUpKey = e.key === CameraItemMovingEnum.MOVE_UP;
    let secondId;
    if (isUpKey && previousCameraId) {
      secondId = previousCameraId;
    } else if (!isUpKey && nextCameraId) {
      secondId = nextCameraId;
    }

    if (secondId) handleChangeCameraSortIndex({ firstId: camera.id, secondId: secondId });
  };

  return (
    <Form
      form={form}
      name={CameraFormEnum.FORM_NAME}
      initialValues={{ remember: true, url: camera?.url, name: camera?.name }}
      onFinish={onSubmitForm}
      onFieldsChange={onFieldsChange}
      scrollToFirstError={true}
      layout='vertical'>
      <div className={styles.container}>
        {isEditMode ? (
          <>
            <Form.Item
              name={CameraFormEnum.CAM_NAME}
              rules={[{ required: true, message: CameraFormEnum.CAM_NAME_ERR_MESSAGE }]}
              required={false}>
              <Input placeholder={CameraFormEnum.CAM_NAME_LABEL} />
            </Form.Item>

            <Form.Item
              name={CameraFormEnum.BROADCAST_LINK_NAME}
              rules={[{ required: true, message: CameraFormEnum.BROADCAST_LINK_ERR_MESSAGE }]}
              required={false}>
              <Input placeholder={CameraFormEnum.BROADCAST_LINK_LABEL} />
            </Form.Item>
          </>
        ) : (
          <>
            <h4 className={styles.name}>{camera.name}</h4>
            <p className={styles.link}>{camera.url}</p>
          </>
        )}

        <div className={styles.actionsWrap}>
          {isEditMode ? (
            <>
              <Button
                icon={<SettingSave />}
                htmlType='submit'
                className={`${styles.actionBtn} ${styles.save}
                                      `}
                disabled={isDisabled}
              />
              <Popconfirm
                title='Удалить камеру?'
                onConfirm={onDeleteCamera}
                okText='Да'
                cancelText='Нет'
                className={`${styles.actionBtn} ${styles.center}`}>
                <SettingDelete />
              </Popconfirm>
            </>
          ) : (
            <Button
              onClick={handleChangeEditMode}
              icon={<SettingPencil />}
              className={`${styles.actionBtn} ${styles.center}`}
            />
          )}
          <Dropdown overlay={menu} className={styles.dropdownMenu}>
            <SettingMenu />
          </Dropdown>
        </div>
      </div>
    </Form>
  );
};
