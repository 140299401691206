import { ICamera, ICameraChangeSortIndex, ICameraID, ICreateCamera, IUpdateCamera } from '@Models/';
import { liqApi } from '@Store/liq';

export const camerasApi = liqApi.injectEndpoints({
  endpoints: build => ({
    getCameras: build.query<ICamera[], undefined>({
      query: () => `/api/cameras`,
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Cameras', id } as const)),
              { type: 'Cameras', id: 'LIST' },
            ]
          : [{ type: 'Cameras', id: 'LIST' }],
    }),
    createCamera: build.mutation<ICamera, ICreateCamera>({
      query: data => ({
        url: '/api/cameras',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Cameras', id: 'LIST' }],
    }),
    updateCamera: build.mutation<ICamera, IUpdateCamera>({
      query: ({ id, ...data }) => ({
        url: `/api/cameras/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'Cameras', id: 'LIST' }],
    }),
    deleteCamera: build.mutation<ICamera, ICameraID>({
      query: ({ id }) => ({
        url: `/api/cameras/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Cameras', id: 'LIST' }],
    }),
    changeCameraSortIndex: build.mutation<ICamera[], ICameraChangeSortIndex>({
      query: data => ({
        url: `/api/cameras/sort`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Cameras', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetCamerasQuery,
  useLazyGetCamerasQuery,
  useCreateCameraMutation,
  useUpdateCameraMutation,
  useDeleteCameraMutation,
  useChangeCameraSortIndexMutation,
} = camerasApi;
