import React, { FC } from 'react';
import { DatePicker } from 'antd';
import clsx from 'clsx';

import { StrokeIcon } from '@Components/ui/icons';
import styles from './DatePicker.module.less';
import { IDatePicker } from './DatePicker.types';

export const CustomDatePicker: FC<IDatePicker> = ({
  value,
  onChange,
  className,
  popupClassName,
}) => {
  return (
    <DatePicker
      className={clsx(styles.input, className)}
      dropdownClassName={clsx(styles.popup, popupClassName)}
      prevIcon={<StrokeIcon />}
      nextIcon={<StrokeIcon style={{ transform: 'rotate(180deg)' }} />}
      allowClear={false}
      showToday={false}
      value={value}
      onChange={onChange}
      showNow={false}
      format={'DD.MM.YYYY'}
    />
  );
};
