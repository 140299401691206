import { createApi } from '@reduxjs/toolkit/query/react';
import { IAuthParams, IUser, IYClientsServerResponse } from '@Models/';
import { customQuery } from '@Services/';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customQuery,
  tagTypes: ['Stores', 'Records', 'PastRecords'],
  endpoints: build => ({
    login: build.mutation<IUser, IAuthParams>({
      query: (authParams: IAuthParams) => ({
        url: `/auth`,
        method: 'POST',
        body: authParams,
      }),
      transformResponse: (response: IYClientsServerResponse<IUser>) => response.data,
    }),
  }),
});

export const { useLoginMutation } = authApi;
