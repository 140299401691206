import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Spin } from 'antd';
import clsx from 'clsx';

import styles from './List.module.less';
import { IList } from '@Components/ui/List/List.types';
import { pastRecordsPageCount } from '@Constants/';
import { CustomPagination } from '@Components/ui/Pagination';

export const List: FC<PropsWithChildren<IList>> = ({
  children,
  className,
  isLoading = false,
  onChangePage,
  currentPage,
  totalRecords = pastRecordsPageCount,
}) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, [currentPage]);
  return (
    <>
      <ul className={clsx(styles.list, className)}>
        <Spin spinning={isLoading}>{children}</Spin>
      </ul>
      {totalRecords > pastRecordsPageCount && (
        <CustomPagination current={currentPage} total={totalRecords} onChange={onChangePage} />
      )}
    </>
  );
};
