import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SettingPencilSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 17.0987L16.7694 3.79369C16.8622 3.70066 16.9724 3.62684 17.0938 3.57644C17.2152 3.52605 17.3453 3.50007 17.4767 3.5C17.6081 3.49993 17.7382 3.52575 17.8597 3.57601C17.9811 3.62627 18.0914 3.69996 18.1843 3.79289L20.3041 5.91259C20.397 6.00552 20.4707 6.11585 20.5209 6.23728C20.5712 6.35871 20.597 6.48885 20.5969 6.62026C20.5968 6.75168 20.5709 6.88179 20.5205 7.00316C20.4701 7.12453 20.3962 7.23477 20.3032 7.32759L7 20.5987H3.5V17.0987Z'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.9941 5.60352L18.4941 9.10352'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 16.5996L7.5 20.0996'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const SettingPencil: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={SettingPencilSVG} {...props} />
);
