import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import styles from './Record.module.less';
import { IRecordComponent } from './Record.types';
import { UploadPhoto } from '@Components/ui/UploadPhoto';
import { ImageTypesEnum } from '@Models/';
import { RecordVideoEdit } from '@Components/RecordVideoEdit';

export const Record: FC<IRecordComponent> = ({
  currentTime,
  isEditCameras,
  record,
  liqData,
  allCameras = [],
  onChangeImage,
  onDeleteImage,
  onUpdateRecordLiq,
}) => {
  const [isNow, setIsNow] = useState(false);

  const startTime = DateTime.fromISO(record.datetime);
  const endTime = startTime.plus({ second: record.seance_length });

  useEffect(() => {
    if (currentTime === undefined) return;
    setIsNow(currentTime >= startTime && currentTime < endTime);
  }, [currentTime]);

  return (
    <article className={styles.container}>
      <div>
        <p className={styles.time}>
          {startTime.toLocaleString(DateTime.TIME_24_SIMPLE)} –{' '}
          {endTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
        </p>
        {isNow && <span className={styles.now}>СЕЙЧАС</span>}
      </div>
      <div>
        <p className={styles.services}>
          {record.services.map(service => `${service.title}`).join(', ') || 'Услуги не выбраны'}
        </p>

        <div className={styles.client}>
          {record.client ? (
            <>
              <p>{record.client.name}</p>
              <a className={styles.phone} href={`tel:${record.client.phone}`}>
                {record.client.phone}
              </a>
            </>
          ) : (
            <p>Клиент не заполнил данные</p>
          )}
        </div>
      </div>
      <div className={clsx(styles.actions, !isEditCameras && styles.actionsPhotos)}>
        {isEditCameras && onUpdateRecordLiq ? (
          <RecordVideoEdit
            yclientsId={record.id}
            enabledCameras={!liqData?.disabledCameras}
            disableCamerasEndSession={liqData?.disableCamerasEndSession}
            cameras={liqData?.cameras}
            allCameras={allCameras}
            onUpdateRecordLiq={onUpdateRecordLiq}
          />
        ) : (
          <>
            <UploadPhoto
              id={record.id}
              url={liqData?.beforeImage || null}
              text={'до'}
              onChange={onChangeImage}
              onDelete={onDeleteImage}
              imageType={ImageTypesEnum.BEFORE}
            />
            <UploadPhoto
              id={record.id}
              url={liqData?.afterImage || null}
              text={'после'}
              onChange={onChangeImage}
              onDelete={onDeleteImage}
              imageType={ImageTypesEnum.AFTER}
            />
          </>
        )}
      </div>
    </article>
  );
};
