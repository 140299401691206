import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthTokensLIQ, IUser } from '@Models/';
import { LS_AUTH_KEY } from '@Constants/';
import { UseLocalStorage } from '@Helpers/';

interface AuthState {
  user: IUser | null;
  tokens: IAuthTokensLIQ | null;
  isLogged: boolean;
}

const authLC = new UseLocalStorage<AuthState>(LS_AUTH_KEY, {
  user: null,
  tokens: null,
  isLogged: false,
});

const initialState: AuthState = authLC.get();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.isLogged = true;
      authLC.set(state);
    },
    logoutUser(state) {
      state.user = null;
      state.isLogged = false;
      authLC.set(state);
    },
    setTokens(state, action: PayloadAction<IAuthTokensLIQ>) {
      state.tokens = action.payload;
      authLC.set(state);
    },
    deleteAccessToken(state) {
      state.tokens = state.tokens ? { ...state.tokens, accessToken: '' } : null;
      authLC.set(state);
    },
    deleteTokens(state) {
      state.tokens = null;
      authLC.set(state);
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
