import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Switch } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DefaultOptionType } from 'rc-select/lib/Select';

import styles from './RecordVideoEdit.module.less';
import { IRecordVideoEditComponent } from '@Components/RecordVideoEdit/RecordVideoEdit.types';
import { CustomSelect } from '@Components/ui/Select';

export const RecordVideoEdit: FC<IRecordVideoEditComponent> = ({
  yclientsId,
  enabledCameras,
  disableCamerasEndSession,
  cameras,
  allCameras,
  onUpdateRecordLiq,
}) => {
  const [selectedCamera, setSelectedCamera] = useState<DefaultOptionType | null>(null);
  const [isEnableVideo, setEnableVideo] = useState(true);
  const [isDisableEndSession, setDisableEndSession] = useState(true);

  useEffect(() => {
    if (cameras?.length && cameras[0].id !== selectedCamera?.value) {
      setSelectedCamera({ value: cameras[0].id, label: cameras[0].name });
      console.log({ cameras });
    }
  }, [cameras]);

  useEffect(() => {
    if (enabledCameras !== undefined && enabledCameras !== isEnableVideo) {
      setEnableVideo(enabledCameras);
    }
  }, [enabledCameras]);

  useEffect(() => {
    if (
      disableCamerasEndSession !== undefined &&
      disableCamerasEndSession !== isDisableEndSession
    ) {
      setDisableEndSession(disableCamerasEndSession);
    }
  }, [disableCamerasEndSession]);

  const handleChangeStore = (item: DefaultOptionType) => {
    onUpdateRecordLiq({
      yclientsId,
      camerasIds: item.value ? String(item.value) : null,
    });
    setSelectedCamera(item);
  };

  const handleSwitchDisabledCameras = (checked: boolean) => {
    onUpdateRecordLiq({ yclientsId, disabledCameras: !checked });
    setEnableVideo(checked);
  };

  const handleCheckedDisableEndSession = (e: CheckboxChangeEvent) => {
    onUpdateRecordLiq({ yclientsId, disableCamerasEndSession: e.target.checked });
    setDisableEndSession(e.target.checked);
  };
  return (
    <>
      <CustomSelect
        placeholder={'Выберите камеру'}
        className={styles.select}
        value={selectedCamera}
        options={allCameras || []}
        onChange={handleChangeStore}
      />
      {/*      <CustomMultiSelect
        value={selectedCameras.length ? selectedCameras : cameras?.map(({ id }) => id) || []}
        options={allCameras || []}
        onChange={handleChangeStore}
      />*/}
      <div className={styles.switch}>
        <Switch checked={isEnableVideo} onChange={handleSwitchDisabledCameras} />
        <span>{isEnableVideo ? 'Камеры включены' : 'Камеры выключены'}</span>
      </div>
      <Checkbox checked={isDisableEndSession} onChange={handleCheckedDisableEndSession}>
        Выключить в конце сеанса
      </Checkbox>
    </>
  );
};
