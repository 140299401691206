import qs from 'qs';

import { IPastRecordsParams, IRecord, IRecordsParams, IYClientsServerResponse } from '@Models/';
import { authApi } from '@Store/auth';
import { pastRecordsPageCount } from '@Constants/';

export const recordsApi = authApi.injectEndpoints({
  endpoints: build => ({
    getRecords: build.query<IRecord[], IRecordsParams>({
      query: ({ company_id, start_date, end_date }) =>
        `/records/${company_id}?${qs.stringify({ start_date, end_date, count: 300 })}`,
      transformResponse: (response: IYClientsServerResponse<IRecord[]>) =>
        response.data.filter(({ attendance }) => attendance !== 1).reverse(),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: 'Records', id, start_date: arg.start_date } as const),
              ),
              { type: 'Records', id: 'LIST' },
            ]
          : [{ type: 'Records', id: 'LIST' }],
    }),
    getPastRecords: build.query<IYClientsServerResponse<IRecord[]>, IPastRecordsParams>({
      query: ({ company_id, start_date, end_date, page }) =>
        `/records/${company_id}?${qs.stringify({
          start_date,
          end_date,
          page,
          count: pastRecordsPageCount,
        })}`,
      transformResponse: (response: IYClientsServerResponse<IRecord[]>) => {
        response.data = response.data.filter(({ attendance }) => attendance === 1);
        return response;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: 'PastRecords', id, start_date: arg.start_date } as const),
              ),
              { type: 'PastRecords', id: 'LIST' },
            ]
          : [{ type: 'PastRecords', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetRecordsQuery, useLazyGetPastRecordsQuery } = recordsApi;
