import qs from 'qs';

import {
  IChangeAfterImageForRecordsLiq,
  IChangeBeforeImageForRecordsLiq,
  IRecordLiq,
  IRecordsForObjectResponse,
  IRecordsLiqParams,
  IUpdateRecordLiq,
} from '@Models/';
import { liqApi } from '@Store/liq';

export const recordsLiqApi = liqApi.injectEndpoints({
  endpoints: build => ({
    getRecordsLiq: build.query<IRecordsForObjectResponse, IRecordsLiqParams>({
      query: ({ yclientsIds, objectResponse = true }) =>
        `/api/records?${qs.stringify({ yclientsIds: yclientsIds.join(','), objectResponse })}`,
      providesTags: result =>
        result
          ? [
              ...Object.keys(result).map(id => ({ type: 'Records', id } as const)),
              { type: 'Records', id: 'LIST' },
            ]
          : [{ type: 'Records', id: 'LIST' }],
    }),
    changeBeforeImage: build.mutation<IRecordLiq, IChangeBeforeImageForRecordsLiq>({
      query: ({ yclientsId, beforeImageFile }) => {
        const formData = new FormData();
        formData.append('beforeImageFile', beforeImageFile);
        return {
          url: `/api/records/${yclientsId}/before-image`,
          method: 'PATCH',
          body: formData,
        };
      },
      invalidatesTags: () => [{ type: 'Records', id: 'LIST' }],
    }),
    changeAfterImage: build.mutation<IRecordLiq, IChangeAfterImageForRecordsLiq>({
      query: ({ yclientsId, afterImageFile }) => {
        const formData = new FormData();
        formData.append('afterImageFile', afterImageFile);
        return {
          url: `/api/records/${yclientsId}/after-image`,
          method: 'PATCH',
          body: formData,
        };
      },
      invalidatesTags: () => [{ type: 'Records', id: 'LIST' }],
    }),
    deleteBeforeImage: build.mutation<IRecordLiq, number>({
      query: yclientsId => ({
        url: `/api/records/${yclientsId}/before-image`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Records', id: 'LIST' }],
    }),
    deleteAfterImage: build.mutation<IRecordLiq, number>({
      query: yclientsId => ({
        url: `/api/records/${yclientsId}/after-image`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Records', id: 'LIST' }],
    }),
    updateRecordLiq: build.mutation<IRecordLiq, IUpdateRecordLiq>({
      query: ({ yclientsId, camerasIds, disabledCameras, disableCamerasEndSession }) => {
        const formData = new FormData();
        if (disabledCameras !== undefined)
          formData.append('disabledCameras', String(disabledCameras));
        if (disableCamerasEndSession !== undefined)
          formData.append('disableCamerasEndSession', String(disableCamerasEndSession));
        if (camerasIds !== undefined) formData.append('camerasIds', String(camerasIds));
        return {
          url: `/api/records/${yclientsId}`,
          method: 'PATCH',
          body: formData,
        };
      },
      invalidatesTags: () => [{ type: 'Records', id: 'LIST' }],
    }),
  }),
});

export const {
  useLazyGetRecordsLiqQuery,
  useChangeBeforeImageMutation,
  useChangeAfterImageMutation,
  useDeleteBeforeImageMutation,
  useDeleteAfterImageMutation,
  useUpdateRecordLiqMutation,
} = recordsLiqApi;
