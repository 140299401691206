import { IStore, IYClientsServerResponse } from '@Models/';
import { authApi } from '@Store/auth';

export const storesApi = authApi.injectEndpoints({
  endpoints: build => ({
    getStores: build.query<IStore[], undefined>({
      query: () => `/companies?my=1`,
      transformResponse: (response: IYClientsServerResponse<IStore[]>) => response.data,
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Stores', id } as const)),
              { type: 'Stores', id: 'LIST' },
            ]
          : [{ type: 'Stores', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetStoresQuery } = storesApi;
