import React, { FC, PropsWithChildren } from 'react';

import classes from './BaseLayout.module.less';
import { Layout } from 'antd';

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Layout.Content className={classes.layout}>
      <div className={classes.container}>{children}</div>
    </Layout.Content>
  );
};
