import React from 'react';
import './App.less';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RecordsPage } from '@Pages/RecordsPage';
import { AuthorizedWrapper, NoAuthorizedWrapper } from '@Components/wrappers/';
import { Login } from '@Pages/Login';
import { SettingsPage } from '@Pages/SettingsPage';
import { PastRecordsPage } from '@Pages/PastRecordsPage';

export const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to='/records' />} />
        <Route element={<AuthorizedWrapper />}>
          <Route path='/records' element={<RecordsPage />} />

          <Route path='/past-records' element={<PastRecordsPage />} />

          <Route path='/setting' element={<SettingsPage />} />
        </Route>
        <Route element={<NoAuthorizedWrapper />}>
          <Route path='/login' element={<Login />} />
        </Route>
        <Route path='*' element={<>Not Found</>} />
      </Routes>
    </>
  );
};
