import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { Container } from '@Components/ui/Container';
import { useAppSelector } from '@Hooks/';
import { Record } from '@Components/Record';
import {
  ImageTypesEnum,
  IOnChangeImage,
  IOnDeleteImage,
  IOnUpdateRecordLiq,
  StoresTypeEnum,
} from '@Models/';
import { List } from '@Components/ui/List';
import { useLazyGetCamerasQuery } from '@Store/cameras';
import { useLazyGetRecordsQuery } from '@Store/records';
import { ListNoResult } from '@Components/ui/ListNoResult';
import {
  useChangeAfterImageMutation,
  useChangeBeforeImageMutation,
  useLazyGetRecordsLiqQuery,
  useUpdateRecordLiqMutation,
  useDeleteBeforeImageMutation,
  useDeleteAfterImageMutation,
} from '@Store/records-liq';
import { message } from 'antd';

export const RecordsPage = () => {
  const [currentTime, setCurrentTime] = useState<DateTime>(DateTime.now());
  const { store, storeType } = useAppSelector(state => state.stores);
  const [getRecords, { data: records, isFetching, isError, isLoading }] = useLazyGetRecordsQuery();
  const [getCameras, { data: cameras }] = useLazyGetCamerasQuery();
  const [getRecordsLiq, { data: recordsLiq }] = useLazyGetRecordsLiqQuery();
  const [changeBeforeImage] = useChangeBeforeImageMutation();
  const [changeAfterImage] = useChangeAfterImageMutation();
  const [updateRecordLiq] = useUpdateRecordLiqMutation();
  const [deleteBeforeImage] = useDeleteBeforeImageMutation();
  const [deleteAfterImage] = useDeleteAfterImageMutation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, 30 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (store?.id)
      getRecords({
        company_id: store.id,
        start_date: currentTime.toISODate(),
        end_date: currentTime
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .toISODate(),
        dateTime: currentTime.toISO(),
      });
  }, [store, currentTime]);

  useEffect(() => {
    if (storeType === StoresTypeEnum.petbar) {
      getCameras(undefined);
    }
  }, [storeType]);

  useEffect(() => {
    if (!records) return;
    getRecordsLiq({ yclientsIds: records.map(({ id }) => id) });
  }, [records]);

  const handleChangeImage: IOnChangeImage = async (
    yclientsId,
    file,
    imageType,
    onDisableLoading,
  ) => {
    try {
      if (typeof file === 'string') return;
      if (imageType === ImageTypesEnum.BEFORE) {
        await changeBeforeImage({ yclientsId, beforeImageFile: file }).unwrap();
      } else {
        await changeAfterImage({ yclientsId, afterImageFile: file }).unwrap();
      }
      // eslint-disable-next-line
    } catch (e: any) {
      if (e.data.message) message.error(e.data.message);
      onDisableLoading();
    }
  };

  const handleDeleteImage: IOnDeleteImage = async (yclientsId, imageType, onDisableLoading) => {
    try {
      if (imageType === ImageTypesEnum.BEFORE) {
        await deleteBeforeImage(yclientsId).unwrap();
      } else {
        await deleteAfterImage(yclientsId).unwrap();
      }
      // eslint-disable-next-line
    } catch (e: any) {
      if (e.data.message) message.error(e.data.message);
      onDisableLoading();
    }
  };

  const handleUpdateRecordLiq: IOnUpdateRecordLiq = data => {
    return updateRecordLiq(data).unwrap();
  };

  return (
    <Container
      title={`${store?.title.toUpperCase()} – Актуальные записи на сегодня`}
      isPageContainer>
      <List isLoading={isFetching || isLoading}>
        {!isError && records?.length ? (
          records.map(record => (
            <li key={record.id}>
              <Record
                currentTime={currentTime}
                allCameras={cameras?.map(({ id, name }) => ({ value: id, label: name })) || []}
                record={record}
                liqData={recordsLiq ? recordsLiq[record.id] : undefined}
                onChangeImage={handleChangeImage}
                onDeleteImage={handleDeleteImage}
                onUpdateRecordLiq={handleUpdateRecordLiq}
                isEditCameras={storeType === StoresTypeEnum.petbar || false}
              />
            </li>
          ))
        ) : (
          <ListNoResult
            isLoading={isFetching || isLoading}
            isError={isError}
            errorText={'При загрузке записей произошла ошибка'}
            emptyText={'Нет записей на этот день'}
          />
        )}
      </List>
    </Container>
  );
};
