import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi, authReducer } from './auth/';
import { liqApi } from './liq';
import { storesReducer } from './stores';
import { settingReducer } from './setting';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [liqApi.reducerPath]: liqApi.reducer,
    auth: authReducer,
    stores: storesReducer,
    setting: settingReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([authApi.middleware, liqApi.middleware]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
