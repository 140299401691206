import React, { FC, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';

import styles from './CameraForm.module.less';
import { CameraFormEnum, ICameraForm } from './CameraForm.types';
import { ICreateCamera } from '@Models/';

export const CameraForm: FC<ICameraForm> = ({ camera, onSubmit }) => {
  const [form] = Form.useForm<ICreateCamera>();
  const [isDisabled, setIsDisabled] = useState(
    camera ? Object.values(camera).some(({ value }) => !!value) : true,
  );

  const onFinish = async (values: ICreateCamera) => {
    await onSubmit(values, camera?.id);
    if (!camera?.id) {
      form.resetFields();
      setIsDisabled(true);
    }
  };

  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const hasEmptyValues = allFields.some(({ value }) => !value);
    setIsDisabled(hasErrors || hasEmptyValues);
  };

  return (
    <>
      <Form
        form={form}
        name={CameraFormEnum.FORM_NAME}
        initialValues={{ remember: true, url: camera?.url, name: camera?.name }}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        scrollToFirstError={true}
        layout='vertical'>
        <div className={styles.inputs_block}>
          <Form.Item
            name={CameraFormEnum.CAM_NAME}
            label={CameraFormEnum.CAM_NAME_LABEL}
            rules={[{ required: true, message: CameraFormEnum.CAM_NAME_ERR_MESSAGE }]}
            required={false}>
            <Input placeholder={CameraFormEnum.CAM_NAME_PLACEHOLDER} />
          </Form.Item>

          <Form.Item
            name={CameraFormEnum.BROADCAST_LINK_NAME}
            label={CameraFormEnum.BROADCAST_LINK_LABEL}
            rules={[{ required: true, message: CameraFormEnum.BROADCAST_LINK_ERR_MESSAGE }]}
            required={false}>
            <Input placeholder={CameraFormEnum.BROADCAST_LINK_PLACEHOLDER} />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            disabled={isDisabled}
            type='primary'
            shape='round'
            className={styles.btn}
            htmlType='submit'>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
