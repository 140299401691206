import React, { FC, useEffect, useState } from 'react';
import { message, Popconfirm, Upload, UploadProps } from 'antd';
import clsx from 'clsx';
import { LoadingOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';

import styles from './UploadPhoto.module.less';
import { Photo } from '@Components/ui/icons';
import { IUploadPhoto } from '@Components/ui/UploadPhoto/UploadPhoto.types';
import { imageMaxSize, MESSAGE_IMAGE, supportImageMime } from '@Constants/';
import { TrashIcon } from '@Components/ui/icons/TrashIcon';

export const UploadPhoto: FC<IUploadPhoto> = ({ id, url, text, onChange, onDelete, imageType }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (url !== undefined) setLoading(false);
  }, [url]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = supportImageMime.includes(file.type);
    if (!isJpgOrPng) {
      message.error(MESSAGE_IMAGE.SUPPORT_MIME);
    }
    const isLt2M = file.size <= imageMaxSize;
    if (!isLt2M) {
      message.error(MESSAGE_IMAGE.MAX_SIZE);
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps['customRequest'] = async ({ file }) => {
    setLoading(true);
    await onChange(id, file, imageType, disableLoading);
  };

  const handleConfirmDelete = async (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    setLoading(true);
    await onDelete(id, imageType, disableLoading);
  };

  const handleCancelDelete = async (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
  };

  const onClickDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const disableLoading = () => setLoading(false);

  return (
    <Upload
      accept={supportImageMime.join(',')}
      customRequest={handleChange}
      beforeUpload={beforeUpload}
      listType='picture-card'
      showUploadList={false}>
      <div className={styles.container}>
        <div className={clsx(styles.icon, isLoading && styles.iconLoading)}>
          {isLoading ? <LoadingOutlined /> : !url && <Photo />}
        </div>
        {url && (
          <>
            <img src={url} alt={`Фото ${text}`} className={styles.img} />
            <Popconfirm
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={onClickDelete}
              title='Удалить фото?'
              onCancel={handleCancelDelete}
              onConfirm={handleConfirmDelete}
              okText='Да'
              cancelText='Нет'
              className={`${styles.btnDelete}`}>
              <TrashIcon />
            </Popconfirm>
          </>
        )}
        <span className={clsx(styles.text, url && styles.textWhite)}>{text}</span>
      </div>
    </Upload>
  );
};
