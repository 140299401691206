import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StrokeSVG = () => (
  <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.12132 0.292893C8.51184 0.683417 8.51184 1.31658 8.12132 1.70711L2.82843 7L8.12132 12.2929C8.51184 12.6834 8.51184 13.3166 8.12132 13.7071C7.7308 14.0976 7.09763 14.0976 6.70711 13.7071L0 7L6.70711 0.292893C7.09763 -0.0976311 7.7308 -0.0976311 8.12132 0.292893Z'
      fill='#2D2D2D'
    />
  </svg>
);

export const StrokeIcon: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={StrokeSVG} {...props} />
);
