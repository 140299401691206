export const redirectPage = '/records';
export const redirectAuthPage = '/login';
export const pastRecordsPageCount = process.env.REACT_APP_PAST_RECORDS_COUNT
  ? parseInt(process.env.REACT_APP_PAST_RECORDS_COUNT)
  : 50;

export const imageMaxSize = 10485760;
export const supportImageMime = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/svg+xml',
  'image/gif',
];

export const MESSAGE_IMAGE = {
  SUPPORT_MIME: 'Разрешена загрузка файлов jpg, jpeg, png, gif или webp',
  MAX_SIZE: 'Максимальный размер изображения 10 мб',
};
