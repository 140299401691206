import React, { FC } from 'react';
import { Select } from 'antd';
import { ISelect } from './Select.types';
import styles from './Select.module.less';
import { ArrowSelect } from '@Components/ui/icons';
import clsx from 'clsx';

export const CustomSelect: FC<ISelect> = ({ placeholder, value, options, onChange, className }) => {
  return (
    <Select
      placeholder={placeholder}
      className={clsx(styles.input, className)}
      dropdownClassName={styles.dropdown}
      dropdownAlign={{
        offset: [0, 11],
      }}
      labelInValue
      value={value}
      onChange={onChange}
      options={options}
      suffixIcon={<ArrowSelect />}
    />
  );
};
