import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './ListNoResult.module.less';
import { IListNoResult } from './ListNoResult.types';

export const ListNoResult: FC<IListNoResult> = ({
  className,
  isLoading,
  isError,
  errorText = 'При загрузке данных произошла ошибка',
  processText,
  emptyText = 'Нет данных',
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <p className={clsx(styles.text)}>
        {isLoading ? processText : isError ? errorText : emptyText}
      </p>
    </div>
  );
};
