import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import 'moment/locale/ru';

import { App } from './App';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ConfigProvider locale={ruRu}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
);
