import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SettingDeleteSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.62 3.37997C21.1267 3.8866 21.1267 4.708 20.62 5.21462L5.21462 20.62C4.708 21.1267 3.8866 21.1267 3.37997 20.62C2.87334 20.1134 2.87334 19.292 3.37997 18.7854L18.7854 3.37997C19.292 2.87334 20.1134 2.87334 20.62 3.37997Z'
      fill='#F5222D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.37997 3.37997C3.8866 2.87334 4.708 2.87334 5.21462 3.37997L20.62 18.7854C21.1267 19.292 21.1267 20.1134 20.62 20.62C20.1134 21.1267 19.292 21.1267 18.7854 20.62L3.37997 5.21462C2.87334 4.708 2.87334 3.8866 3.37997 3.37997Z'
      fill='#F5222D'
    />
  </svg>
);

export const SettingDelete: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={SettingDeleteSVG} {...props} />
);
