import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TrashIconSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.8892 22.23H7.21924C6.48989 22.23 5.79042 21.9403 5.27469 21.4245C4.75897 20.9088 4.46924 20.2093 4.46924 19.48V6.72C4.46924 6.52109 4.54826 6.33032 4.68891 6.18967C4.82956 6.04902 5.02033 5.97 5.21924 5.97C5.41815 5.97 5.60892 6.04902 5.74957 6.18967C5.89022 6.33032 5.96924 6.52109 5.96924 6.72V19.48C5.96924 19.8115 6.10093 20.1295 6.33536 20.3639C6.56978 20.5983 6.88772 20.73 7.21924 20.73H16.8892C17.0542 20.7313 17.2179 20.7 17.3707 20.6377C17.5235 20.5755 17.6625 20.4836 17.7796 20.3674C17.8967 20.2512 17.9897 20.113 18.0531 19.9607C18.1166 19.8084 18.1492 19.645 18.1492 19.48V6.72C18.1492 6.52109 18.2283 6.33032 18.3689 6.18967C18.5096 6.04902 18.7003 5.97 18.8992 5.97C19.0982 5.97 19.2889 6.04902 19.4296 6.18967C19.5702 6.33032 19.6492 6.52109 19.6492 6.72V19.48C19.6492 19.842 19.5778 20.2004 19.439 20.5347C19.3001 20.869 19.0967 21.1726 18.8403 21.4281C18.5838 21.6836 18.2795 21.8859 17.9447 22.0235C17.6099 22.1612 17.2512 22.2313 16.8892 22.23Z'
      fill='#F5222D'
    />
    <path
      d='M15.8293 7.47C15.6312 7.46741 15.4419 7.38756 15.3018 7.24747C15.1617 7.10737 15.0819 6.91811 15.0793 6.72V3.72C15.0793 3.6537 15.053 3.59011 15.0061 3.54322C14.9592 3.49634 14.8956 3.47 14.8293 3.47H9.2793C9.21299 3.47 9.1494 3.49634 9.10252 3.54322C9.05564 3.59011 9.0293 3.6537 9.0293 3.72V6.72C9.0293 6.91891 8.95028 7.10968 8.80963 7.25033C8.66897 7.39098 8.47821 7.47 8.2793 7.47C8.08038 7.47 7.88962 7.39098 7.74897 7.25033C7.60831 7.10968 7.5293 6.91891 7.5293 6.72V3.72C7.5397 3.26191 7.72835 2.82594 8.05514 2.50475C8.38193 2.18357 8.8211 2.00249 9.2793 2H14.8293C15.2934 2 15.7385 2.18437 16.0667 2.51256C16.3949 2.84075 16.5793 3.28587 16.5793 3.75V6.75C16.5715 6.94367 16.4891 7.12681 16.3493 7.26104C16.2095 7.39527 16.0231 7.47015 15.8293 7.47Z'
      fill='#F5222D'
    />
    <path
      d='M9.97925 18C9.78034 18 9.58957 17.921 9.44892 17.7803C9.30827 17.6397 9.22925 17.4489 9.22925 17.25V11.11C9.22925 10.9111 9.30827 10.7203 9.44892 10.5797C9.58957 10.439 9.78034 10.36 9.97925 10.36C10.1782 10.36 10.3689 10.439 10.5096 10.5797C10.6502 10.7203 10.7292 10.9111 10.7292 11.11V17.27C10.7279 17.3672 10.7075 17.4631 10.6691 17.5524C10.6307 17.6417 10.5751 17.7225 10.5055 17.7903C10.4358 17.8581 10.3535 17.9115 10.2632 17.9475C10.1729 17.9835 10.0764 18.0013 9.97925 18Z'
      fill='#F5222D'
    />
    <path
      d='M14.1493 18C13.9504 18 13.7596 17.921 13.619 17.7803C13.4783 17.6397 13.3993 17.4489 13.3993 17.25V11.11C13.3993 10.9111 13.4783 10.7203 13.619 10.5797C13.7596 10.439 13.9504 10.36 14.1493 10.36C14.3482 10.36 14.539 10.439 14.6796 10.5797C14.8203 10.7203 14.8993 10.9111 14.8993 11.11V17.27C14.8941 17.4654 14.8128 17.6511 14.6727 17.7874C14.5326 17.9238 14.3448 18.0001 14.1493 18Z'
      fill='#F5222D'
    />
    <path
      d='M21.2092 7.46999H2.89925C2.79032 7.4921 2.67784 7.48973 2.56994 7.46305C2.46204 7.43637 2.36142 7.38603 2.27536 7.31569C2.1893 7.24535 2.11995 7.15676 2.07233 7.05633C2.0247 6.9559 2 6.84614 2 6.73499C2 6.62384 2.0247 6.51407 2.07233 6.41364C2.11995 6.31321 2.1893 6.22462 2.27536 6.15428C2.36142 6.08394 2.46204 6.03361 2.56994 6.00692C2.67784 5.98024 2.79032 5.97787 2.89925 5.99999H21.2092C21.4082 5.99999 21.5989 6.079 21.7396 6.21966C21.8802 6.36031 21.9592 6.55107 21.9592 6.74999C21.9592 6.9489 21.8802 7.13966 21.7396 7.28032C21.5989 7.42097 21.4082 7.49999 21.2092 7.49999V7.46999Z'
      fill='#F5222D'
    />
  </svg>
);

export const TrashIcon: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={TrashIconSVG} {...props} />
);
