import React, { FC } from 'react';

import { ILogo } from './Logo.types';
import { StoresTypeEnum } from '@Models/';

import logoImg from '@Assets/images/icons/logo.svg';
import logoPetbarImg from '@Assets/images/icons/logo-petbar.svg';
import logoSkinbarImg from '@Assets/images/icons/logo-skinbar.svg';
import clsx from 'clsx';

export const Logo: FC<ILogo> = ({ type, className }) => {
  return (
    <img
      className={clsx('logo', className)}
      src={
        type === undefined
          ? logoImg
          : type === StoresTypeEnum.skinbar
          ? logoSkinbarImg
          : logoPetbarImg
      }
      alt='LIQ logo'
    />
  );
};
