import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { authActions } from '@Store/auth';
import { RootState } from '../store';
import { message } from 'antd';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_YCLIENTS_URL,
  prepareHeaders: (headers, { getState }) => {
    const userToken = (getState() as RootState).auth.user?.user_token;
    headers.set('Accept', 'application/vnd.api.v2+json');
    headers.set(
      'Authorization',
      `Bearer ${process.env.REACT_APP_YCLIENTS_PARTNER_TOKEN}${
        userToken ? `, User ${userToken}` : ''
      }`,
    );
    return headers;
  },
});
export const customQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      api.dispatch(authActions.logoutUser());
      message.error('Необходимо повторно авторизоваться');
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
