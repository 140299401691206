import { RcFile } from 'antd/es/upload';
import { ICamera } from './camera';

export enum ImageTypesEnum {
  AFTER = 'after',
  BEFORE = 'before',
}

export interface IRecordLiq {
  id: number;
  yclientsId: number;
  beforeImage: string | null;
  afterImage: string | null;
  cameras: ICamera[];
  disabledCameras: boolean;
  disableCamerasEndSession: boolean;
}

export interface IRecordsLiqParams {
  yclientsIds: number[];
  objectResponse?: boolean;
}

export interface IRecordsForObjectResponse {
  [id: number]: IRecordLiq;
}

export interface IUpdateRecordLiq extends Pick<IRecordLiq, 'yclientsId'> {
  disabledCameras?: boolean;
  disableCamerasEndSession?: boolean;
  camerasIds?: string | null;
}

export interface IChangeBeforeImageForRecordsLiq extends Pick<IRecordLiq, 'yclientsId'> {
  beforeImageFile: RcFile | Blob;
}

export interface IChangeAfterImageForRecordsLiq extends Pick<IRecordLiq, 'yclientsId'> {
  afterImageFile: RcFile | Blob;
}

export type IOnChangeImage = (
  yclientsId: number,
  file: string | RcFile | Blob,
  imageType: ImageTypesEnum,
  onDisableLoading: () => void,
) => Promise<void>;

export type IOnDeleteImage = (
  yclientsId: number,
  imageType: ImageTypesEnum,
  onDisableLoading: () => void,
) => Promise<void>;

export type IOnUpdateRecordLiq = (data: IUpdateRecordLiq) => Promise<IRecordLiq>;
