import React, { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './Container.module.less';
import { IContainer } from './Container.types';

export const Container: FC<PropsWithChildren<IContainer>> = ({
  title,
  children,
  className,
  isPageContainer,
}) => {
  return (
    <div className={clsx(styles.wrap, className, isPageContainer && styles.page)}>
      {title && <h1>{title}</h1>}
      {children}
    </div>
  );
};
