import { ICamera, ISubmitCameraForm } from '@Models/';

export interface ICameraForm {
  camera?: ICamera;
  onSubmit: ISubmitCameraForm;
}

export enum CameraFormEnum {
  FORM_NAME = 'edit_record',

  CAM_NAME_PLACEHOLDER = 'Назовите камеру',
  CAM_NAME = 'name',
  CAM_NAME_ERR_MESSAGE = 'Введите название камеры',
  CAM_NAME_LABEL = 'Название камеры',

  BROADCAST_LINK_LABEL = 'Ссылка на трансляцию',
  BROADCAST_LINK_NAME = 'url',
  BROADCAST_LINK_ERR_MESSAGE = 'Введите ссылку на трансляцию',
  BROADCAST_LINK_PLACEHOLDER = 'Вставьте ссылку',

  ERR_MESSAGE = 'При выполнении запроса произошла ошибка',
  INFO_MESSAGE = 'Сохранено',

  INFO_MESSAGE_DELETED = 'Камера удалена',
  INFO_MESSAGE_CHANGE_SORT_INDEX = 'Расположение камер изменено',
}
