import React, { useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import styles from './login.module.less';

import { Logo } from '@Components/ui/Logo';
import { ArrowIcon } from '@Components/ui/icons/ArrowIcon';
import { useAuth } from '@Hooks/';
import { IAuthParams } from '@Models/';
import { FieldData } from 'rc-field-form/lib/interface';

const { Title } = Typography;

export const Login = () => {
  const [form] = Form.useForm();
  const { handleLogin } = useAuth();
  const [isDisabled, setIsDisabled] = useState(true);

  const onFinish = async (values: IAuthParams) => {
    try {
      await handleLogin(values);
      message.info('Добро пожаловать!');
      // eslint-disable-next-line
    } catch (e: any) {
      if (e?.status === 404) {
        form.setFields([
          {
            name: 'login',
            errors: [''],
          },
          {
            name: 'password',
            errors: ['Неверный логин или пароль'],
          },
        ]);
      } else {
        message.error('При выполнении запроса произошла ошибка.');
      }
    }
  };

  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const hasEmptyValues = allFields.some(({ value }) => !value);
    setIsDisabled(hasErrors || hasEmptyValues);
  };

  return (
    <div className={styles.wrap}>
      <Form
        form={form}
        name='normal_login'
        className={styles.form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        scrollToFirstError={true}
        layout='vertical'>
        <div className={styles.header}>
          <Logo />
          <Title level={1}>Войдите как администратор</Title>
        </div>
        <Form.Item name='login' rules={[{ required: true, message: 'Введите логин пользователя' }]}>
          <Input placeholder='Логин' />
        </Form.Item>

        <Form.Item
          name='password'
          rules={[
            { required: true, message: 'Введите пароль пользователя' },
            {
              pattern: /(?=^.{6,}$)(?=.*\d)(?=.*[A-Za-z]).*$/gm,
              message: 'Минимум 8 символов, 1 цифра и 1 латинская буква',
            },
          ]}>
          <Input.Password type='password' placeholder='Пароль' />
        </Form.Item>

        <Form.Item>
          <Button disabled={isDisabled} type='primary' htmlType='submit' block>
            ВОЙТИ <ArrowIcon />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
