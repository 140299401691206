import React from 'react';
import { List } from '@Components/ui/List';
import {
  useChangeCameraSortIndexMutation,
  useCreateCameraMutation,
  useDeleteCameraMutation,
  useGetCamerasQuery,
  useUpdateCameraMutation,
} from '@Store/cameras';
import { CameraItem } from '@Pages/SettingsPage/SettingCameras/CameraItem';
import { CameraForm } from '@Pages/SettingsPage/SettingCameras/CameraForm';
import { ICameraChangeSortIndexFunction, IDeleteCameraFunction, ISubmitCameraForm } from '@Models/';
import { message } from 'antd';
import { CameraFormEnum } from '@Pages/SettingsPage/SettingCameras/CameraForm/CameraForm.types';

export const SettingCameras = () => {
  const { data: cameras } = useGetCamerasQuery(undefined);
  const [createCamera] = useCreateCameraMutation();
  const [updateCamera] = useUpdateCameraMutation();
  const [deleteCamera] = useDeleteCameraMutation();
  const [changeCameraSortIndex] = useChangeCameraSortIndexMutation();

  const handleCameraFormSubmit: ISubmitCameraForm = async (data, id) => {
    try {
      if (id) {
        await updateCamera({ ...data, id }).unwrap();
      } else {
        await createCamera(data).unwrap();
      }
      message.info(CameraFormEnum.INFO_MESSAGE);
      // eslint-disable-next-line
    } catch (e: any) {
      message.error(CameraFormEnum.ERR_MESSAGE);
    }
  };

  const handleChangeCameraSortIndex: ICameraChangeSortIndexFunction = async data => {
    try {
      await changeCameraSortIndex(data).unwrap();
      message.info(CameraFormEnum.INFO_MESSAGE_CHANGE_SORT_INDEX);
      // eslint-disable-next-line
    } catch (e: any) {
      message.error(CameraFormEnum.ERR_MESSAGE);
    }
  };

  const handleDeleteCamera: IDeleteCameraFunction = async data => {
    try {
      await deleteCamera(data).unwrap();
      message.info(CameraFormEnum.INFO_MESSAGE_DELETED);
      // eslint-disable-next-line
    } catch (e: any) {
      console.log(e);
      message.error(CameraFormEnum.ERR_MESSAGE);
    }
  };

  return (
    <>
      <List>
        {cameras?.map((camera, index) => {
          return (
            <li key={camera.id}>
              <CameraItem
                camera={camera}
                previousCameraId={cameras[index - 1]?.id}
                nextCameraId={cameras[index + 1]?.id}
                handleSubmitForm={handleCameraFormSubmit}
                handleChangeCameraSortIndex={handleChangeCameraSortIndex}
                handleDeleteCamera={handleDeleteCamera}
              />
            </li>
          );
        })}

        <li>
          <CameraForm onSubmit={handleCameraFormSubmit} />
        </li>
      </List>
    </>
  );
};
