import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SettingMenuSVG = () => (
  <svg width='5' height='20' viewBox='0 0 5 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0C3.88071 0 5 1.11929 5 2.5ZM5 10C5 11.3807 3.88071 12.5 2.5 12.5C1.11929 12.5 0 11.3807 0 10C0 8.61929 1.11929 7.5 2.5 7.5C3.88071 7.5 5 8.61929 5 10ZM2.5 20C3.88071 20 5 18.8807 5 17.5C5 16.1193 3.88071 15 2.5 15C1.11929 15 0 16.1193 0 17.5C0 18.8807 1.11929 20 2.5 20Z'
      fill='#333333'
    />
  </svg>
);

export const SettingMenu: FC<Partial<CustomIconComponentProps>> = props => (
  <Icon component={SettingMenuSVG} {...props} />
);
