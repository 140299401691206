import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStore, StoresTypeEnum } from '@Models/';
import { LS_STORES_KEY } from '@Constants/';
import { UseLocalStorage } from '@Helpers/';

interface StoresState {
  stores: IStore[];
  store: IStore | null;
  storeType?: StoresTypeEnum;
}

const storesLC = new UseLocalStorage<StoresState>(LS_STORES_KEY, {
  stores: [],
  store: null,
});

const initialState: StoresState = storesLC.get();

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setStores(state, action: PayloadAction<IStore[]>) {
      state.stores = action.payload;
      if (!state.store?.id || !action.payload.find(({ id }) => id === state.store?.id)) {
        state.store = action.payload[0];
        state.storeType = action.payload[0].title.toLowerCase().includes(StoresTypeEnum.skinbar)
          ? StoresTypeEnum.skinbar
          : StoresTypeEnum.petbar;
      }
      storesLC.set(state);
    },
    setStore(state, action: PayloadAction<IStore | null>) {
      state.store = action.payload;
      state.storeType = action.payload
        ? action.payload.title.toLowerCase().includes(StoresTypeEnum.skinbar)
          ? StoresTypeEnum.skinbar
          : StoresTypeEnum.petbar
        : undefined;
      storesLC.set(state);
    },
    resetStores(state) {
      state.stores = [];
      state.store = null;
      state.storeType = undefined;
      storesLC.set(state);
    },
  },
});

export const storesActions = storesSlice.actions;
export const storesReducer = storesSlice.reducer;
